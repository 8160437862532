(function fitFinderIntegration() {
  if (window._fitAnalytics) return;

  window._fitAnalytics = function (imports) {
    const { Integration, SizeAlert } = imports;

    const sizeAlertConfig = {
      sizes: 'label[data-test-id="vf-size-picker"] span[data-test-id="vf-size-picker-text"]',
      sizeCode: {
        selector: '.',
        prop: 'text'
      },

      style: {
        tooltipContainer: {
          position: 'absolute',
          width: '100%'
        },
        tooltip: {
          'background-color': '#ffffff',
          border: '1.25px solid rgb(160, 160, 160)',
          'box-shadow': 'rgba(0, 0, 0, 0.16) 0px 2px 4px',
          'line-height': '22px',
          margin: '0 0 0 0.7rem'
        },
        headline: {
          display: 'none'
        },
        arrow: {
          'background-color': '#ffffff',
          'border-bottom': '1.25px solid rgb(160, 160, 160)',
          'border-left': '1.25px solid rgb(160, 160, 160)',
          transform: 'skew(0deg, -45deg) !important',
          left: '-1px',
          width: '15px',
          height: '15px'
        }
      },

      cart: {
        source: {
          node: window,
          path: 'fitFinder.operations.getCartItems'
        },
        serial: (cartItem: any) => cartItem.itemSubgroupId,
        size: (cartItem: any) => cartItem.size
      }
    };
    //@ts-ignore
    const msa = new SizeAlert(sizeAlertConfig);

    const dispatchEvent = (eventAction: string, eventLabel: string) => {
      // @ts-ignore
      window.dataLayer?.push({
        event: 'loadEventData',
        eventCategory: 'Fit Analytics',
        eventAction,
        eventLabel,
        nonInteractive: false
      });
    };

    const integration = new Integration({
      debug: false,
      addOns: [msa],
      shop: {
        prefix: 'tnf_eu',
        shopLanguage: {
          node: window,
          path: 'fitFinder.shopLanguage'
        },
        country: {
          node: window,
          path: 'fitFinder.shopCountry'
        },
        userId: {
          node: window,
          path: 'fitFinder.userId'
        },
        rootDomain: {
          node: window,
          path: 'location.hostname',
          process: /(thenorthface\.[a-z\.]+$)/
        },
        shopSessionId: {
          node: window,
          path: 'fitFinder.shopSessionId'
        },

        // @ts-ignore
        isReady: () => Boolean(window.fitFinder)
      },

      globalEvents: {
        fit_finder_open: function () {
          dispatchEvent('Size Finder Open', 'Started');
        },

        metric_event: function (name) {
          if (name === 'sizeRecommendation') {
            dispatchEvent('Size Finder Recommendation', 'Completed');
          }
        }
      },

      scope: 'body',

      product: {
        container: '.fit-finder-container',

        isLoaded: {
          process: () =>
            Boolean(
              // @ts-ignore
              window.fitFinder?.currentItemSubgroupId &&
                // @ts-ignore
                window.fitFinder?.shopCountry &&
                // @ts-ignore
                window.fitFinder?.shopLanguage
            )
        },

        hasManufacturedSizes: true,

        serials: {
          node: window,
          path: 'fitFinder.allItemSubgroupIds'
        },
        currentSerial: {
          node: window,
          path: 'fitFinder.currentItemSubgroupId'
        },
        thumbnail: {
          node: window,
          path: 'fitFinder.mainImageLink'
        },

        addToCartElement: () => {
          // @ts-ignore
          return window.fitFinder?.operations?.getAddToCartBtn?.();
        },

        addToCart: (serial, size) => {
          // @ts-ignore
          window.fitFinder?.operations?.addToCart?.(
            // @ts-ignore
            fitFinder.currentItemSubgroupId,
            size
          );
        },

        sizes: {
          node: window,
          items: {
            path: 'fitFinder.sizes'
          },
          code: {
            path: 'value'
          },
          isAvailable: {
            path: 'isAvailable'
          }
        }
      },

      button: {
        anchor: '#fit-finder-container',
        anchorPlacement: 'prepend',
        style: {
          button: {
            width: 'fit-content'
          },
          text: {
            'font-size': '14px',
            'font-family': 'inherit',
            color: 'inherit',
            'font-weight': '400',
            'text-decoration': 'underline'
          },
          image: {
            display: 'none'
          }
        },

        textAttrs: {
          onmouseover: 'this.style.textDecoration= "none"; return false',
          onmouseleave: 'this.style.textDecoration= "underline"; return false'
        }
      },

      sizeChartButton: {
        // @ts-ignore
        elem: () => fitFinder?.operations?.getSizeChartLink?.(),
        shouldHide: false
      }
    });

    window._fitAnalytics!.integration = integration;
    integration.start();
  };

  function init() {
    const scriptElem = document.createElement('script');
    scriptElem.setAttribute('src', '//widget.fitanalytics.com/widget_pdp.js');
    scriptElem.setAttribute('type', 'text/javascript');
    document.body.appendChild(scriptElem);
  }

  if (document.readyState === 'interactive' || document.readyState === 'complete') {
    init();
  } else {
    document.addEventListener('DOMContentLoaded', function () {
      init();
    });
  }
})();

//---------------------------------------
// PLP integration
//---------------------------------------

interface Window {
  _fitAnalyticsTotalFit: any;
  fitFinder: any;
}

const HEIGHT_UNIT = {
  metric: 'cm',
  imperial: ['ft', 'in']
};
interface CustomShop {
  prefix: string;
  isMobile: boolean;
  isTouchDevice: boolean;
  el: {
    plpContainer: string;
    totalFitButtonAnchor: string;
  };
  totalFitLocales: string[];
  initialize: () => void;
  isLoaded: () => boolean;
  onLoaded: () => void;
  watchButton: () => void;
  isTotalFitLocale: () => boolean;
  isPLP: () => boolean;
  getLanguage: () => string;
  getCountry: () => string;
  getUserId: () => string;
  getRootDomain: () => string | null;
  getGender: () => string;
  getCategory: () => string;
  loadTotalFitWidget: () => void;
  installTotalFitButton: () => void;
  getShopSessionId: () => string;
  totalfit: TotalFit;
  tfwidget?: any;
  $?: any;
  fetchProfileAndSetText: (details?: any) => void;
}

interface TotalFit {
  elementId: string;
  text: Record<string, LocaleText>;
  getDefaultText: (text: LocaleText) => string;
  getActiveProfile: () => Profile;
  getActiveProfileText: (text: LocaleText, profile: Profile) => string;
  getFormattedProfile: (profile: Profile) => object;
  convertLengthM2I: (centimetre: number | undefined) => number[];
  getFormattedHeight: (text: LocaleText, profile: object) => string;
  getText: (details?: object) => string;
  setText: (details: object) => void;
  buildButton: () => unknown;
  isInitialized: boolean;
  show: () => void;
  hide: () => void;
  find: () => any;
  onClick: () => boolean;
  getIconCss: () => Record<string, string>;
  install: () => void;
  $button?: any;
}

interface LocaleText {
  default: string;
  gender: Record<string, string>;
  heightUnits: {
    metric: string;
    imperial: string[];
  };
}

interface Profile {
  id?: string;
  gender?: string;
  height?: number;
  heightDisplayUnits?: string;
  shoesReferenceSize?: {
    name: string;
  };
  profileStatus?: Record<string, CategoryStatus>;
}

interface CategoryStatus {
  missingValues?: any[];
}

(function fitFinderOnPLPIntegration() {
  if (window._fitAnalyticsTotalFit) return;

  const shop: CustomShop = {
    prefix: 'tnf_eu-',
    isMobile:
      /iphone|android|iemobile|blackberry/i.test(window.navigator.userAgent) &&
      ('mozInnerScreenX' in window // Firefox is buggy (https://bugzilla.mozilla.org/show_bug.cgi?id=970346)
        ? 'onorientationchange' in window && 'ontouchstart' in window
        : 'ontouchstart' in window),
    isTouchDevice:
      /ipad|iphone|android|iemobile|blackberry/i.test(window.navigator.userAgent) &&
      (('onorientationchange' in window && 'ontouchstart' in window) || 'ontouchstart' in window),
    el: {
      plpContainer: '#fit-finder-plp',
      totalFitButtonAnchor: '#fit-finder-plp'
    },
    totalFitLocales: [
      'de_DE',
      'fr_FR',
      'it_IT',
      'cs_CZ',
      'en_GB',
      'de_AT',
      'de_LU',
      'en_DK',
      'en_FI',
      'en_IE',
      'en_LU',
      'en_SE',
      'es_ES',
      'fr_BE',
      'nl_BE',
      'nl_NL',
      'pl_PL',
      'pt_PT',
      'da_DK',
      'sv_SE',
      'de_CH',
      'it_CH',
      'fr_CH',
      'sv_FI',
      'en_CH',
      'en_PT',
      'de_BE',
      'fr_LU',
      'nl_LU'
    ],
    initialize() {
      const checkIfLoaded = setInterval(() => {
        if (shop.isLoaded()) {
          clearInterval(checkIfLoaded);
          shop.onLoaded();
        }
      }, 100);
    },

    isLoaded() {
      return (
        typeof window._fitAnalyticsTotalFit === 'function' &&
        window.TotalFitWidget &&
        window.TotalFitWidget.jQuery &&
        window.fitFinder &&
        shop.isPLP()
      );
    },

    onLoaded() {
      shop.$ = window.TotalFitWidget.jQuery;

      if (shop.isPLP() && shop.isTotalFitLocale()) {
        shop.loadTotalFitWidget();
      }
    },

    watchButton() {
      setInterval(() => {
        const isTotalFitButtonInView = this.totalfit.find()?.prop('isConnected');
        if (!isTotalFitButtonInView && shop.isPLP()) {
          this.totalfit.install();
          this.fetchProfileAndSetText();
          shop.totalfit.show();
        }
      }, 100);
    },

    isTotalFitLocale() {
      return shop.totalFitLocales.includes(`${shop.getLanguage()}_${shop.getCountry()}`);
    },

    isPLP() {
      return !!document.querySelector(shop.el.plpContainer);
    },

    getLanguage() {
      return window.fitFinder?.shopLanguage ?? '';
    },

    getCountry() {
      return window.fitFinder?.shopCountry ?? '';
    },

    getUserId() {
      return window.fitFinder?.userId ?? '';
    },

    getRootDomain() {
      const rootDomain = /thenorthface\.[a-z\.]+$/.exec(window.location.hostname);
      return rootDomain != null ? rootDomain[0] : null;
    },

    getGender() {
      return window.fitFinder?.gender ?? '';
    },

    getCategory() {
      return window.fitFinder?.category ?? '';
    },

    getShopSessionId() {
      return window.fitFinder?.shopSessionId ?? '';
    },

    loadTotalFitWidget() {
      shop.tfwidget = new window.TotalFitWidget({
        shopPrefix: shop.prefix.slice(0, -1),
        language: shop.getLanguage(),
        shopCountry: shop.getCountry(),
        userId: shop.getUserId(),
        localCookieDomain: shop.getRootDomain(),
        gender: shop.getGender(),
        category: shop.getCategory(),
        shopSessionId: shop.getShopSessionId(),
        load: (_: any, details: any) => {
          shop.installTotalFitButton();

          if (details?.profile) {
            shop.totalfit.setText(details);
          } else {
            let trials = 20;
            const waitForProfileData = setInterval(() => {
              const profile = shop.totalfit.getActiveProfile();
              if (Object.keys(profile).length !== 0) {
                clearInterval(waitForProfileData);
                shop.fetchProfileAndSetText({ details, profile });
              } else if (--trials === 0) clearInterval(waitForProfileData);
            }, 250);
          }
          shop.watchButton();
        },
        close: (_: any, details: any) => {
          shop.totalfit.setText(details);
        }
      });
    },

    fetchProfileAndSetText(
      { details, profile }: any = { profile: shop.totalfit.getActiveProfile() }
    ) {
      const formattedProfile = shop.totalfit.getFormattedProfile(profile);
      shop.totalfit.setText({ ...details, profile: formattedProfile });
    },

    installTotalFitButton() {
      shop.totalfit.install();
      shop.totalfit.show();
    },

    totalfit: {
      elementId: 'fitanalytics__totalfit-button',
      text: {
        en: {
          default: 'Find your size',
          gender: {
            w: 'Woman',
            m: 'Man'
          },
          heightUnits: HEIGHT_UNIT
        },
        de: {
          default: 'Finde deine Größe',
          gender: {
            w: 'Frau',
            m: 'Mann'
          },
          heightUnits: HEIGHT_UNIT
        },
        fr: {
          default: 'Trouver ma taille',
          gender: {
            w: 'Femme',
            m: 'Homme'
          },
          heightUnits: HEIGHT_UNIT
        },
        es: {
          default: 'Encuentra tu talla',
          gender: {
            w: 'Mujer',
            m: 'Hombre'
          },
          heightUnits: HEIGHT_UNIT
        },
        it: {
          default: 'Trova la tua taglia',
          gender: {
            w: 'Donna',
            m: 'Uomo'
          },
          heightUnits: HEIGHT_UNIT
        },
        cs: {
          default: 'Najít mou velikost',
          gender: {
            w: 'Žena',
            m: 'Muž'
          },
          heightUnits: HEIGHT_UNIT
        },
        nl: {
          default: 'Vind uw maat',
          gender: {
            w: 'Dames',
            m: 'Heren'
          },
          heightUnits: HEIGHT_UNIT
        },
        pl: {
          default: 'Znajdź mój rozmiar',
          gender: {
            w: 'Kobieta',
            m: 'Mężczyzna'
          },
          heightUnits: HEIGHT_UNIT
        },
        pt: {
          default: 'Encontrar seu tamanho',
          gender: {
            w: 'Mulher',
            m: 'Homem'
          },
          heightUnits: HEIGHT_UNIT
        },
        da: {
          default: 'Find din størrelse',
          gender: {
            w: 'Kvinde',
            m: 'Mand'
          },
          heightUnits: HEIGHT_UNIT
        },
        sv: {
          default: 'Hitta din storlek',
          gender: {
            w: 'Kvinna',
            m: 'Man'
          },
          heightUnits: HEIGHT_UNIT
        }
      },
      getDefaultText: function (text) {
        return text.default;
      },
      getActiveProfile: function () {
        const currentProfileId = lookup(shop, 'tfwidget.profileStore.data.currentItem', '');
        const profiles = lookup(shop, 'tfwidget.profileStore.data.items', [] as any);
        const currentProfile = profiles.find((profile: any) => profile.id === currentProfileId);
        const primaryProfile = profiles.find((profile: any) => profile.isPrimary);

        return currentProfile || primaryProfile || {};
      },
      getActiveProfileText: function (text, profile) {
        const gender = text.gender[profile.gender as any],
          height = this.getFormattedHeight(text, profile);

        return `${gender}, ${height}`;
      },
      getFormattedProfile: function (profile) {
        let hasRecommendation = false;
        for (const [, categoryStatus] of Object.entries(profile.profileStatus as any)) {
          const { missingValues } = categoryStatus as { missingValues: any[] };
          if (missingValues?.length === 0) {
            hasRecommendation = true;
            break;
          }
        }
        const formattedProfile: {
          gender: string | undefined;
          hasRecommendation: boolean;
          heightUnits?: string;
          height?: any;
          shoeSize?: string;
        } = {
          gender: profile.gender,
          hasRecommendation
        };
        if (profile.height) {
          formattedProfile.heightUnits = profile.heightDisplayUnits;
        }
        if (profile.heightDisplayUnits != 'metric') {
          formattedProfile.height = shop.totalfit.convertLengthM2I(profile.height);
        } else {
          formattedProfile.height = profile.height;
        }
        if (profile.shoesReferenceSize) {
          formattedProfile.shoeSize = profile.shoesReferenceSize.name;
        }
        return formattedProfile;
      },
      convertLengthM2I: function (centimetre) {
        let cm = centimetre || 0;
        if (typeof cm === 'undefined') {
          return [0, 0];
        }
        let inches = cm / 2.54;
        let ft = Math.floor(inches / 12);
        let inch = Math.round(inches % 12);
        if (inch == 12) {
          ft += 1;
          inch = 0;
        }
        return [ft, inch];
      },
      getFormattedHeight: function (text, profile: any) {
        const height = profile.height,
          unit = text.heightUnits[profile.heightUnits as keyof typeof text.heightUnits];

        if (Array.isArray(profile.height)) {
          return `${height[0]} ${unit[0]} ${height[1]} ${unit[1]}`;
        } else {
          return `${height} ${unit}`;
        }
      },
      getText: function (details: any) {
        var text = this.text[shop.getLanguage()] || this.text.en,
          activeProfile = (details && details.profile) || {};

        if (activeProfile && activeProfile.height) {
          return this.getActiveProfileText(text, activeProfile);
        } else {
          return this.getDefaultText(text);
        }
      },
      setText: function (details) {
        this.find()?.find('.fitanalytics__totalfit-button-text').html(this.getText(details));
      },
      buildButton: function () {
        var text = this.getText();
        return shop
          .$('<div></div>')
          .attr({
            id: this.elementId
          })
          .css({
            cursor: 'auto',
            'text-align': shop.isTouchDevice ? 'center' : 'normal',
            display: 'inline-block',
            width: 'auto'
          })
          .append(
            shop
              .$('<a></a>')
              .attr('class', 'fitanalytics__totalfit-button-text')
              .css({
                display: 'inline-block',
                width: 'auto',
                outline: 'none',
                cursor: 'pointer',
                'vertical-align': 'top',
                'font-size': shop.isMobile ? '12px' : '14px',
                'font-weight': '500',
                color: 'black',
                'margin-left': '10px',
                'margin-top': shop.isMobile ? '3px' : '1px'
              })
              .on('click', this.onClick)
              .text(text)
          )
          .append(
            shop.$('<style></style>').text(`
              .fitanalytics__totalfit-button-text::after {
                content: '>';
                font-weight: 500;
                color: inherit;
                margin-left: 5px;
              }`)
          );
      },
      isInitialized: false,
      show: function () {
        this.find()?.show();
      },
      hide: function () {
        this.find()?.hide();
      },
      find: function () {
        return this.$button;
      },
      onClick: function () {
        shop.tfwidget.meterClick('totalFit');
        shop.tfwidget.open();
        return false;
      },
      getIconCss: function () {
        return {
          height: '11px',
          cursor: 'pointer',
          'line-height': '24px',
          display: 'inline-block',
          'margin-right': '7.5px',
          'margin-bottom': '1px'
        };
      },
      install: function () {
        this.$button ||= this.buildButton();
        shop.$(shop.el.totalFitButtonAnchor).append(this.$button);
      }
    }
  };

  function init() {
    var secondScriptElem = document.createElement('script');
    secondScriptElem.setAttribute('src', '//widget.fitanalytics.com/totalfit_zepto.js');
    secondScriptElem.setAttribute('type', 'text/javascript');
    document.body.appendChild(secondScriptElem);

    shop.initialize();

    window._fitAnalyticsTotalFit = function () {};
    window._fitAnalyticsTotalFit.shop = shop;
  }

  // useful function to look up values inside deep objects
  var lookup = function (
    obj: { [key: string]: any },
    path: string,
    defval: string | boolean | number | null
  ) {
    obj = obj && obj instanceof Object ? obj : {};
    defval == null && (defval = null);
    path === '.' && (path = '');
    var keys = Object.prototype.toString.apply(path) === '[object Array]' ? path : path.split('.');
    for (var i = 0, n = keys.length; i < n; i++) {
      var v = obj[keys[i]];
      if (v != null) {
        obj = v;
      } else {
        return defval;
      }
    }
    return v;
  };

  if (document.readyState === 'interactive' || document.readyState === 'complete') {
    init();
  } else {
    document.addEventListener('DOMContentLoaded', function () {
      init();
    });
  }
})();
